<template>
    <div class="companyHome">
        <div class="left">
            <!-- 企业统计数据 -->
            <div class="staticData">
                <ul>
                    <li v-for="(v,i) in staticData" :key="i">
                        <div class="img">
                            <img :src="require('../assets/images/'+v.imgUrl)"/>
                        </div>
                        <div class="info">
                            <h3>{{v.num}}</h3>
                            <span>{{v.name}}</span>
                        </div>
                    </li>    
                </ul>
            </div>
            <!-- 岗位详情 -->
            <div class="positionBox">
                <headLine msg='岗位招聘达成比例' :height='height'></headLine>
                <div class="box" >
                    <div v-show="!showBlank" id="jobBar" class="jobBar" ></div>
                    <blankPage v-show="showBlank" msg='还没有岗位招聘达成~'></blankPage>
                </div>
                
            </div>
            <!-- 面试详情 -->
            <div class="interviewBox">
                <headLine msg='面试数据详情' :height='height'></headLine> 
                <div id="interviewPie" class="interviewPie"></div> 
            </div>
        </div>
        <div class="right">
            <!-- 双选会详情 -->
            <div class="dbselectBox">
                <headLine msg='最近参加的双选会' :height='height'></headLine> 
                <div class="box" >
                    <ul v-if="dbselectData.length>0">
                        <li v-for="(v,i) in dbselectData" :key="i" @click="goDoubleElection">
                            <div class="boxleft">
                                <h4>{{v.zpDoubleChoose?v.zpDoubleChoose.dcName:''}}</h4>
                                <span>{{v.zpDoubleChoose?v.zpDoubleChoose.startTime+' ~ '+
                                    v.zpDoubleChoose.endTime:''}}</span>
                            </div>
                            <el-tag 
                                :type="v.state | getStatus('color','db')" disable-transitions>
                                {{v.state | getStatus('name','db')}}
                            </el-tag>
                        </li>
                    </ul>
                    <blankPage v-else msg='还没有参加双选会~'></blankPage>
                </div>
               
            </div>
            <!-- 简历详情 -->
            <div class="resumeBox">
                <headLine msg='最新收到的简历' :height='height'></headLine>
                <div class="box" >
                    <ul v-if="resumeData.length>0">
                        <li v-for="(v,i) in resumeData" :key="i" @click="goResumePerson(v.zpResume)">
                            <div class="boxleft">
                               <img v-if="v.zpResume.sex==0" src='../assets/images/girl.png'/>
                               <img v-else src='../assets/images/boy.png'/>
                               <div>
                                   <h3>{{v.zpResume.name}}</h3>
                                   <span>应聘：{{v.zpJob.jobName}}</span>
                               </div>
                            </div>
                            <el-tag 
                            :type="v.state | getStatus('color','all')" disable-transitions>
                                {{v.state | getStatus('name','all')}}
                            </el-tag>
                        </li>
                    </ul>
                     <blankPage v-else msg='还没有收到简历~'></blankPage>
                </div> 
               
            </div>
        </div>
    </div>
</template>
<script>
// 引入 ECharts 主模块
var echarts = require('echarts/lib/echarts');
// 引入柱状图
require('echarts/lib/chart/bar');
require('echarts/lib/chart/pie') ;
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
import {canvasJs} from '../service/canvas.js'
let interviewData=[
    {
        name:"已邀约",
        value:0,
        key:'invitationNum'
    },
    {
        name:"未赴约",
        value:0,
        key:'unInvitationNum'
    },
    {
        name:"已面试",
        value:0,
        key:'interviewNum'
    },
    {
        name:"面试通过",
        value:0,
        key:'interviewPassNum'
    }
]
export default {
   data() {
       return {
           showBlank:false,
           dbselectData:[],
           resumeData:[],
           height:false,
           imgUrls:'../assets/images/',
           staticData:[
               {
                   name:"双选会(次)",
                   imgUrl:"dbselect.png",
                   num:0
               },
               {
                   name:"岗位(个)",
                   imgUrl:"position2.png",
                   num:0
               },
               {
                   name:"简历(份)",
                   imgUrl:"resume.png",
                   num:0
               },
               {
                   name:"面试(人)",
                   imgUrl:"interview.png",
                   num:0
               }
           ],
           interviewArr:[]
       }
   }, 
   methods: {
       initEcharts(data){
             //岗位招聘达成比柱状图
             let {reachMap}=data
             let sericeData=[],yAxisData=[]
             for(let i in reachMap){
                 yAxisData.push(i)
                 sericeData.push(reachMap[i])
             }
            this.staticData[1].num=sericeData.length
            this.showBlank=sericeData.length>0?false:true
            let myChart1 = echarts.init(document.getElementById('jobBar'));
            let options1=canvasJs.jobBar(yAxisData,sericeData)
            myChart1.setOption(options1)

            //面试情况多个饼状图
            this.interviewArr=JSON.parse(JSON.stringify(interviewData))
            for(let i in data){
                this.interviewArr.forEach(e=>{
                    if(e.key==i){
                        e.value=data[i]
                    }
                })
            }
            let myChart2 = echarts.init(document.getElementById('interviewPie'));
            let options2=canvasJs.interviewPie(this.interviewArr)
            myChart2.setOption(options2)

            //根据窗口的大小变动图表 --- 重点 //若有多个图表变动，可多写
            window.onresize = function(){
                myChart1.resize();
                myChart2.resize();
            }
       },
       getInitData(){
            this.$htp.get_(`/hr/statistics`).then((res)=>{
                let data=res.data
                this.initEcharts(data)
                this.staticData[0].num=data.dcNum
                this.staticData[2].num=data.resumeNum
                this.staticData[3].num=data.interviewNum       
            }) 
       },
       getOtherData(){
           this.$htp.get_(`/hr/statistics2`).then((res)=>{
                let {dcs,resumes}=res.data
                this.dbselectData=dcs.slice(0,3)
                this.resumeData=resumes        
            }) 
       },
       //跳转到个人简历详情
       goResumePerson(zpResume){
           sessionStorage.setItem('zpResume',JSON.stringify(zpResume))
           const { href } = this.$router.resolve({
                name: `resumePerson`
            });
            window.open(href, "_blank");
       },
       //跳转到双选会
       goDoubleElection(){
           this.$emit('changeMune',1,1,'/doubleElection')
       }
   },
   created(){
       this.getInitData()
       this.getOtherData()
   }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/home.scss';
</style>